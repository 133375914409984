export type Wallet = {
    name: string
    pubkey: string
}

export type Viewer = {
    is_auth: boolean
    email_confirmed: boolean
    id: number
    username: string
    email: string
    wallets: Wallet[]
    is_staff: boolean
    is_developer: boolean
    is_marketing: boolean
    is_executive: boolean
    beta_applicant: boolean
    is_holder: boolean
    twitter_connected: boolean
    roles: string[]
}

export const AnonymousViewer = {
    is_auth: false,
}

export interface SignupCredentials {
    username: string
    email: string
    password1: string
    password2: string
}

export interface LoginCredentials {
    username: string
    password: string
}

export interface ValidationError {
    message: string
    code: string
}

export interface SignupBadRequest {
    username?: ValidationError[]
    email?: ValidationError[]
    password1?: ValidationError[]
    password2?: ValidationError[]
    __all__?: ValidationError[]
}

export interface LoginBadRequest {
    username?: ValidationError[]
    password?: ValidationError[]
    __all__?: ValidationError[]
}

export interface ActivationBadRequest {
    code?: ValidationError[]
    __all__?: ValidationError[]
}

export interface ResendActivationEmailBadRequest {
    email_cooldown?: ValidationError[]
    __all__?: ValidationError[]
}

export interface PasswordResetBadRequest {
    email?: ValidationError[]
    __all__?: ValidationError[]
}

export interface PasswordSetBadRequest {
    new_password1?: ValidationError[]
    new_password2?: ValidationError[]
    token: ValidationError[]
    __all__?: ValidationError[]
}
