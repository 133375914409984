import { useMemo } from 'react'
import { useUser } from '~/features/auth/api/useUser'
import { useKillRace, useLeaderboard } from '../api'
import { Countdown } from './Countdown'

export const KillRaceInfo = ({ type }: { type: string }) => {
    const { data } = useKillRace({ type })
    const { data: user } = useUser()
    const { data: user_data } = useLeaderboard({
        type: type,
        mode: 'paid',
        page_size: 1,
        order_by: 'rank',
        filters: { user_id: user?.id },
    })
    const career = useMemo(() => user_data?.pages[0]?.data[0], [user_data])
    return (
        <div className='max-w-[412px] w-full flex flex-col border border-white/50 h-max relative'>
            <div className='p-4 flex flex-col items-center'>
                <KillRaceTitle />
            </div>
            <div className='flex flex-col items-center my-10'>
                <h3 className='font-black text-2xl'>RANK AMONGST THE</h3>
                <h1 className='font-black text-5xl'>TOP {data?.ladder_size}</h1>
                <h3 className='font-black text-2xl'>TO WIN A CASH PRIZE</h3>
            </div>
            <table className='border-spacing-y-2 border-separate font-black'>
                <tbody className=''>
                    <InfoRow left={'DATE'} right={data?.begin ? new Date(data?.begin).toLocaleString() : 'TBA'} />
                    <InfoRow
                        left={'REMAINING'}
                        right={data?.remaining_seconds ? <Countdown seconds={data?.remaining_seconds} /> : '-'}
                    />
                    <tr>
                        <td className='h-4'></td>
                    </tr>
                    <InfoRow left={'TYPE'} right={type.toUpperCase()} />
                    <InfoRow left={'PRIZE'} right={data?.prize.toFixed(2) || '-'} />
                    <InfoRow left={'LADDER'} right={`TOP ${data?.ladder_size}`} />
                </tbody>
            </table>
            <div className='flex flex-col items-center my-10'>
                <h3 className='font-black text-2xl'>POTENTIAL WINNINGS</h3>
                <h1 className='font-black text-5xl text-green-500'>
                    ${data?.pay_strategy?.[career?.rank - 1] || '0.00'}
                </h1>
            </div>
        </div>
    )
}

const KillRaceTitle = () => {
    return <img src={new URL('../assets/killrace_title.svg', import.meta.url).href} />
}

const InfoRow = ({ left, right }) => {
    return (
        <tr className='bg-fixed bg-white/10 h-12'>
            <td className='px-4'>{left}</td>
            <td className='px-4 text-right'>{right}</td>
        </tr>
    )
}
